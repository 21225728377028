import React from "react";
import Field from "./field.js";

const Radio = (props) => {
  const validationFunction = (value) => {
    return typeof value !== "undefined" && value.length;
  };
  return (<Field
    {...props}
    isGroup={ true }
    defaultValue={ props.choices.find(e => e.isSelected)?.value }
    defaultClassName="checkbox-group"
    validationFunction={ validationFunction }
  />);
};

export default Radio;
